<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between flex-wrap">
        <div>{{ $t('companies.edit') }}</div>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="success"
            dark
            class="mr-2"
            @click.prevent="save"
          >
            {{ $t('general.save') }}
          </v-btn>

          <router-link :to="{name:'admin-company-list'}">
            <v-btn
              dark
              outlined
              color="error"
              class="mr-2"
            >
              {{ $t('general.close') }}
            </v-btn>
          </router-link>
        </div>
      </v-card-title>

      <v-card
        flat
        light
        class="rounded-0"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="company.name"
                  :rules="[validator.required]"
                  dense
                  outlined
                  :label="$t('companies.name')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.address"
                  dense
                  outlined
                  :label="$t('companies.address')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.addressAdditional"
                  dense
                  outlined
                  :label="$t('companies.addressAdditional')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.zipCode"
                  dense
                  outlined
                  :label="$t('companies.zipCode')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.city"
                  dense
                  outlined
                  :label="$t('companies.city')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.state"
                  dense
                  outlined
                  :label="$t('companies.state')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.country"
                  dense
                  outlined
                  :label="$t('companies.country')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.email"
                  dense
                  outlined
                  :label="$t('companies.email')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.phone"
                  dense
                  outlined
                  :label="$t('companies.phone')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.companyIdentificationNumber"
                  dense
                  outlined
                  :label="$t('companies.companyIdentificationNumber')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.companyIdentificationType"
                  dense
                  outlined
                  :label="$t('companies.companyIdentificationType')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-select
                  v-model="company.companyType"
                  dense
                  outlined
                  :items="companyTypes"
                  item-value="key"
                  :item-text="item => $t(item.name)"
                  :label="$t('general.type')"
                  class="w-full"
                >
                </v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-select
                  v-model="company.status"
                  dense
                  outlined
                  :items="statusOptions"
                  item-value="key"
                  :item-text="item => $t(item.name)"
                  :label="$t('general.status')"
                  class="w-full"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              dark
              class="mr-2"
              @click.prevent="save"
            >
              {{ $t('general.save') }}
            </v-btn>

            <router-link :to="{name:'admin-company-list'}">
              <v-btn
                dark
                outlined
                color="error"
                class="mr-2"
              >
                {{ $t('general.close') }}
              </v-btn>
            </router-link>
          </v-card-actions>
          <v-form
            ref="form"
            lazy-validation
          >
          </v-form>
        </v-form>
      </v-card>

      <v-card-title class="w-full pl-4 pb-4 d-flex justify-space-between">
        <h2>{{ $t('companies.companyAddresses') }}</h2>

        <v-btn
          icon
          dark
          outlined
          color="primary"
          class="mx-2"
          @click.prevent="showAddressModal = true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-data-table
        v-model="contactSelectedRows"
        :headers="contactTableColumns"
        :items="contactListTable"
        :items-per-page="10"
        :options.sync="optionContacts"
        :server-items-length="totalContacts"
        :loading="loadingContacts"
        disable-sort
      >
        <template #[`item.main_email_address`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.main_email_address }}
            </div>
          </div>
        </template>

        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.name }}
            </div>
          </div>
        </template>

        <template #[`item.address`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.address }}
            </div>
          </div>
        </template>

        <template #[`item.zip_code`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.zip_code }}
            </div>
          </div>
        </template>

        <template #[`item.city`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.city }}
            </div>
          </div>
        </template>

        <template #[`item.phone`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.phone }}</span>
          </div>
        </template>

        <template #[`item.address_type`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ $t($store.getters.formatKeyForTranslation(item.address_type)) }}
            </div>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list link>
              <v-list-item link>
                <v-list-item-title @click.prevent="editContact(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('general.edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteContact(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('general.delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreContact(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('general.restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <v-card-title class="w-full pl-4 pb-4 d-flex justify-space-between">
        <h2>{{ $t('companies.companyUsers') }}</h2>

        <v-btn
          icon
          dark
          outlined
          color="primary"
          class="mx-2"
          @click.prevent="showUserModal = true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-data-table
        v-model="userSelectedRows"
        :headers="userTableColumns"
        :items="userListTable"
        :items-per-page="10"
        :options.sync="optionUsers"
        :server-items-length="totalUsers"
        :loading="loadingUsers"
        disable-sort
      >
        <template #[`item.first_name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.first_name }}
            </div>
          </div>
        </template>

        <template #[`item.last_name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.last_name }}
            </div>
          </div>
        </template>

        <template #[`item.address`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.address }}
            </div>
          </div>
        </template>

        <template #[`item.email`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.email }}
            </div>
          </div>
        </template>

        <template #[`item.mobile`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.mobile }}
            </div>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.status }}</span>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list link>
              <v-list-item link>
                <v-list-item-title @click.prevent="editUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('general.edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('general.delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('general.restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('companies.dataSaved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('general.close' ) }}
        </v-btn>
      </template>
    </v-snackbar>

    <AddAddressModal
      :company-id="$route.params.id"
      :contact="contact"
      :show-address-modal="showAddressModal"
      @new-address-added="newAddressAdded"
      @close-address-modal="showAddressModal = false"
    />

    <AddUserModal
      :user-data-confirm="userDataConfirm"
      :user="user"
      :show-user-modal="showUserModal"
      :user-error-message="userErrorMessage"
      @close-user-modal="showUserModal = false; userErrorMessage = null"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import axiosIns from '@axios'
import { required } from '@core/utils/validation'
import AddAddressModal from '@/views/shared/components/AddAddressModal.vue'
import AddUserModal from '@/views/admin/Company/components/AddUserModal.vue'

export default {
  components: { AddAddressModal, AddUserModal },
  data() {
    const userErrorMessage = null
    const user = { company_id: this.$route.params.id }
    const totalUsers = 0
    const userListTable = []
    const contact = {}
    const showUserModal = false
    const userSelectedRows = []
    const loadingUsers = true
    const optionUsers = {}
    const { companyTypes } = this.$store.state

    const showAddressModal = false
    const contactSelectedRows = []
    const totalContacts = 0
    const contactListTable = []
    const loadingContacts = true
    const optionContacts = {}

    const snackbar = false
    const company = {
      name: null,
      email: null,
      phone: null,
      address: null,
      addressAdditional: null,
      zipCode: null,
      city: null,
      state: null,
      country: null,
      companyIdentificationNumber: null,
      companyIdentificationType: null,
      status: null,
      companyType: null,
    }

    const statusOptions = this.$store.state.baseStatuses

    const contactTableColumns = [
      { text: 'NAME', value: 'name' },
      { text: 'ADDRESS', value: 'address' },
      { text: 'ZIP CODE', value: 'zip_code' },
      { text: 'CITY', value: 'city' },
      { text: 'EMAIL', value: 'main_email_address' },
      { text: 'PHONE', value: 'phone' },
      { text: 'TYPE', value: 'address_type' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    const userTableColumns = [
      { text: 'FIRST NAME', value: 'first_name' },
      { text: 'LAST NAME', value: 'last_name' },
      { text: 'EMAIL', value: 'email' },
      { text: 'MOBILE', value: 'mobile' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    return {
      userErrorMessage,
      companyTypes,
      user,
      totalUsers,
      userListTable,
      contact,
      showAddressModal,
      showUserModal,
      contactSelectedRows,
      userSelectedRows,
      snackbar,
      optionContacts,
      optionUsers,
      loadingContacts,
      loadingUsers,
      company,
      totalContacts,
      contactListTable,
      statusOptions,
      contactTableColumns,
      userTableColumns,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileDocumentEditOutline,
      },
      validator: {
        required,
      },
    }
  },
  watch: {
    showAddressModal() {
      if (!this.showAddressModal) {
        this.contact = {}
      }
    },
    showUserModal() {
      if (!this.showUserModal) {
        this.user = {}
      }
    },
    optionContacts: {
      handler() {
        this.getCompanyContacts()
      },
      deep: true,
    },
    optionUsers: {
      handler() {
        this.getCompanyUsers()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    newAddressAdded() {
      this.getCompanyContacts()
      this.contact = {}
    },

    getDataFromApi() {
      axiosIns.get(`/admin/companies/${this.$route.params.id}`)
        .then(res => {
          const { company } = res.data
          this.company.name = company.name
          this.company.email = company.email
          this.company.phone = company.phone
          this.company.address = company.address
          this.company.addressAdditional = company.address_additional
          this.company.zipCode = company.zip_code
          this.company.city = company.city
          this.company.state = company.state
          this.company.country = company.country
          this.company.companyIdentificationNumber = company.company_identification_number
          this.company.companyIdentificationType = company.company_identification_type
          this.company.status = company.status
          this.company.companyType = company.company_type
        })
        .catch()
    },

    getCompanyContacts() {
      this.loadingContacts = true
      axiosIns.get(`/admin/companies/${this.$route.params.id}/contacts?page=${this.optionContacts.page}&perPage=
      ${this.optionContacts.itemsPerPage}`)
        .then(res => {
          this.totalContacts = res.data.contacts.total
          this.contactListTable = res.data.contacts.data
          this.loadingContacts = false
        })
        .catch()
    },

    getCompanyUsers() {
      this.loadingUsers = true
      axiosIns.get(`/admin/company-users?companyId=${this.$route.params.id}&page=${this.optionUsers.page}&perPage=
      ${this.optionUsers.itemsPerPage}`)
        .then(res => {
          this.totalUsers = res.data.users.total
          this.userListTable = res.data.users.data
          this.loadingUsers = false
        })
        .catch()
    },

    save() {
      if (this.$refs.form.validate()) {
        const company = {
          name: this.company.name,
          email: this.company.email,
          phone: this.company.phone,
          address: this.company.address,
          address_additional: this.company.addressAdditional,
          zip_code: this.company.zipCode,
          city: this.company.city,
          state: this.company.state,
          country: this.company.country,
          company_identification_number: this.company.companyIdentificationNumber,
          company_identification_type: this.company.companyIdentificationType,
          status: this.company.status,
          company_type: this.company.companyType,
        }

        axiosIns({
          method: 'PUT',
          data: company,
          url: `/admin/companies/${this.$route.params.id}`,
        })
          .then(() => {
            this.snackbar = true
          })
          .catch()
      }
    },

    deleteContact(id) {
      axiosIns.delete(`/admin/companies/${this.$route.params.id}/contacts/${id}`)
        .then(() => {
          this.getCompanyContacts()
        })
        .catch()
    },

    deleteUser(id) {
      axiosIns.delete(`/admin/users/${id}`)
        .then(() => {
          this.getCompanyUsers()
        })
        .catch()
    },

    editContact(id) {
      // eslint-disable-next-line prefer-destructuring
      this.contact = this.contactListTable.filter(item => item.id === id)[0]
      this.showAddressModal = true
    },

    editUser(id) {
      // eslint-disable-next-line prefer-destructuring
      this.user = this.userListTable.filter(item => item.id === id)[0]
      this.showUserModal = true
    },

    userDataConfirm() {
      axiosIns({
        method: this.user.id ? 'PUT' : 'POST',
        data: this.user,
        url: this.user.id
          ? `/admin/users/${this.user.id}`
          : '/admin/users',
      })
        .then(() => {
          this.loadingUsers = true
          this.getCompanyUsers()
          this.user = {}
          this.showUserModal = false
          this.userErrorMessage = null
          this.loadingUsers = false
        })
        .catch(err => {
          this.userErrorMessage = err.response.data.errors.email
            || err.response.data.errors.password
            || err.response.data.errors.user
        })
    },
  },
}
</script>
