<template>
  <v-dialog
    v-model="showUserModal"
    max-width="800px"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title>
          {{ $t('users.addOrEditUser') }}
        </v-card-title>

        <v-row
          v-if="userErrorMessage"
          class="no-gutters px-4"
        >
          <v-alert
            class="w-full"
            type="error"
          >
            {{ $t(userErrorMessage) }}
          </v-alert>
        </v-row>

        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.first_name"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('users.firstName')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.last_name"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('users.lastName')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.title"
              dense
              outlined
              :label="$t('users.title')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.function"
              dense
              outlined
              :label="$t('users.function')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.email"
              :rules="[validator.required, validator.emailValidator]"
              dense
              outlined
              :label="$t('users.email')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.correspondence_email"
              dense
              outlined
              :label="$t('users.correspondenceEmail')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.password"
              :rules="user.id ? [] : [validator.passwordValidator]"
              dense
              outlined
              type="password"
              :label="$t('users.password')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.password_confirmation"
              :rules="user.id ? [] : [validator.passwordValidator]"
              dense
              outlined
              type="password"
              :label="$t('users.passwordConfirmation')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-select
              v-model="user.language"
              :rules="[validator.required]"
              :items="languageOptions"
              item-value="id"
              :item-text="item => $t(item.name)"
              dense
              outlined
              :label="$t('users.language')"
              class="w-full"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-select
              v-model="user.correspondence_language"
              :rules="[validator.required]"
              :items="languageOptions"
              item-value="id"
              :item-text="item => $t(item.name)"
              dense
              outlined
              :label="$t('users.correspondenceLanguage')"
              class="w-full"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.mobile"
              dense
              outlined
              :label="$t('users.mobile')"
              class="w-full"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="user.phone"
              dense
              outlined
              :label="$t('users.phone')"
              class="w-full"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-select
              v-model="user.user_type"
              :rules="[validator.required]"
              :items="userTypeOptions"
              item-value="key"
              :item-text="item => $t(item.name)"
              dense
              outlined
              :label="$t('users.userType')"
              class="w-full"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-select
              v-model="user.status"
              :rules="[validator.required]"
              dense
              outlined
              :items="statusOptions"
              :item-text="item => $t(item.name)"
              item-value="key"
              :label="$t('general.status')"
              class="w-full"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="no-gutters px-4">
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('users.twoFactorVerificationMethod') }}
            </v-card-title>
            <v-card-text>
              <v-btn
                value="mail"
                :label="$t('users.email')"
                :color="user.two_factor_verification_method === 'mail' ? 'primary' : 'secondary'"
                class="ma-1"
                fab
                dark
                @click.prevent="user.two_factor_verification_method === 'mail' ?
                  user.two_factor_verification_method = null :
                  user.two_factor_verification_method = 'mail'"
              >
                <v-icon dark>
                  {{ icons.mdiEmailAlert }}
                </v-icon>
              </v-btn>
              <v-btn
                v-if="user.mobile"
                value="sms"
                :label="$t('users.mobile')"
                :color="user.two_factor_verification_method === 'sms' ? 'primary' : 'secondary'"
                class="ma-1"
                fab
                dark
                @click.prevent="user.two_factor_verification_method === 'sms' ?
                  user.two_factor_verification_method = null :
                  user.two_factor_verification_method = 'sms'"
              >
                <v-icon dark>
                  {{ icons.mdiCellphoneKey }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions class="mt-6">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('close-user-modal')"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          @click="saveUser"
        >
          {{ $t('general.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiEmailAlert, mdiCellphoneKey } from '@mdi/js'
import axiosIns from '@axios'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'

export default {
  name: 'AddUserModal',
  props: {
    userErrorMessage: {},
    userDataConfirm: {},
    user: {},
    showUserModal: {},
  },
  data() {
    const companies = []

    const userTypeOptions = this.$store.state.userTypes

    return {
      userTypeOptions,
      companies,
      languageOptions: this.$store.state.languages,
      statusOptions: this.$store.state.baseStatuses,
      icons: {
        mdiEmailAlert,
        mdiCellphoneKey,
      },
      validator: {
        emailValidator,
        passwordValidator,
        required,
      },
    }
  },
  watch: {
    showUserModal() {
      if (!this.showUserModal) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    filterCompanies(filter) {
      if (filter && filter.length >= 2) {
        axiosIns.get(`/admin/companies?filter=${filter}`).then(res => {
          this.companies = res.data.companies.data
        }).catch()
      }
    },
    saveUser() {
      if (this.$refs.form.validate()) {
        this.userDataConfirm()
      }
    },
  },
}
</script>
